/* eslint-disable class-methods-use-this */
import firebase from 'firebase/app';
import '@firebase/messaging';

import Api from './state/Api';
import { FIREBASE, APP_NAMES } from './constants';

const sendTokenToServer = async (token) => {
  const platform = 'web'; // TODO some magic with user agent
  await Api.req.post('/notifications/token', {
    token: token,
    platform,
    in_use: true,
    app_name: APP_NAMES[platform],
  });
  localStorage.setItem('notificationToken', token);
};

class Firebase {
  constructor() {
    if (!Firebase.instance) {
      Firebase.instance = this;
    }

    return Firebase.instance;
  }

  async register() {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', async () => {
        firebase.initializeApp({
          // messagingSenderId: FIREBASE.MESSAGING_SENDER_ID,
          apiKey: "AIzaSyCvt5BvHDEQKKnaR53dimDahw2iQ4xYhHg",
          authDomain: "tevecompras-fcm.firebaseapp.com",
          databaseURL: "https://tevecompras-fcm.firebaseio.com",
          projectId: "tevecompras-fcm",
          storageBucket: "tevecompras-fcm.appspot.com",
          messagingSenderId: "808247182873",
          appId: "1:808247182873:web:ecd4eae8688fe1a5140c88",
          measurementId: "G-P5FN9KEX9H"
        });

        try {
          // console.log(FIREBASE.MESSAGING_SENDER_ID);
          this.messaging = firebase.messaging();
          this.registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js?v=11');
          this.messaging.useServiceWorker(this.registration);
          this.onTokenRefresh();
        } catch (error) {
          console.log(error)
          // eslint-disable-next-line
          console.log('firebase not supported by browser');
        }
      });
    }
  }

  onMessage(user) {
    console.log('hola');
    this.messaging.onMessage((payload) => {
      const options = {
        body: payload.data.body,
        icon: './App/logo.png',
      };
      console.log('showNotification Firebase');
      console.log(payload);
      console.log('showNotification Firebase');

      if (payload.data.author_id !== user.id.toString()) {
        this.registration.showNotification(payload.data.title, options);
      }
    });
  }

  onTokenRefresh() {
    this.messaging.onTokenRefresh(async () => {
      const refreshedToken = await this.messaging.getToken();
      sendTokenToServer(refreshedToken);
    });
  }

  getPermission() {
    return new Promise(async (resolve) => {
      let permissionGranted = false;

      const errors = {
        'messaging/permission-default': 'Debes permitor que el sitio acepte notificaciones',
        'messaging/permission-blocked': 'Este sitio fue bloqueado para recibir notificaciones. Por favor desbloquear desde la configuración del navegador',
        default: 'No se ha podido suscribir a las notificaciones',
      };

      try {
        /* request permission if not granted */
        if (Notification.permission !== 'granted') {
          await this.messaging.requestPermission();
          localStorage.removeItem('notificationToken');
        }
        /* get instance token if not available */
        if (localStorage.getItem('notificationToken') !== null) {
          permissionGranted = true;
        } else {
          const token = await this.messaging.getToken();
          console.log('token do usuario:', token);

          sendTokenToServer(token);
          localStorage.setItem('notificationToken', token);
          permissionGranted = true;
        }

        return resolve(permissionGranted);
      } catch (err) {
        if (err.code && errors[err.code]) {
          return resolve(false);
        }
        return resolve(false);
      }
    });
  }
}

export default new Firebase();
