export const getMe = () => ({ auth: state }) => state.me;

export const isSuper = () => ({ auth: state }) => state.isSuper;

export const isAdmin = () => ({ auth: state }) => state.isAdmin;

export const isMarketingUser = () => ({ auth: state }) => state.isMarketingUser;

export const isLoggingIn = () => ({ auth: state }) => state.loggingIn;

export const isAutoLoggingIn = () => ({ auth: state }) => state.loginAuto;

export const getLoginError = () => ({ auth: state }) => state.loginError;

