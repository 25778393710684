import React, { useState, useEffect } from "react";
import moment from 'moment';
import ScrollToBottom  from 'react-scroll-to-bottom';


import Message from "./Message";
import SendBox from "./SendBox";
import ActionsButton from './ActionsButton';
import AutoResponsesDrawer from './AutoResponsesDrawer';
import Disposition from './Disposition';
import ChatSkeleton from './ChatSkeleton';
import { makeStyles } from "@material-ui/core/styles";
import Chip from '@material-ui/core/Chip';


import { useDispatch, useSelector,shallowEqual } from 'react-redux';
import * as contactsActions from '../../../state/contacts/actions';
import * as contactsSelector from '../../../state/contacts/selectors';
import * as authSelectors from '../../../state/auth/selectors';
import * as channelActions from '../../../state/channels/actions';
import ChatOffline from "./ChatOffline";
import Api from '../../../state/Api';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import HomeIcon from '@material-ui/icons/Home';
import WarningIcon from '@material-ui/icons/Warning';
import { green, grey, red } from "@material-ui/core/colors";
import { getContactClientInfo } from './../../../state/contacts/selectors';
import {  Avatar, CardHeader, CardContent, List, ListItem, ListItemText, Paper, Typography, CardActions, Fab, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Button, Box, Tooltip } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Bubble from './Bubble';

const useStyles = makeStyles(theme => ({
	  rightContainer: {
        position: 'relative',
        flex: 1,
        backgroundColor: 'rgba(230,220,210,0.9)',
        backgroundRepeat: "repeat",
        bottom: 0,
        top: 0,
        overflow: "auto"
    }, 
	  followB: {
      color: '#ffcc00',
      backgroundColor: 'red',
      content: '•'
    },
    pointer : {
      cursor: 'pointer'
    },
    panelContainer : {
      backgroundColor : 'white',
      color: '#000',
      padding : '10px',
      height : '122px',
      borderBottom : '1px solid #5f5f5f',
      display : 'flex',
      textDecoration: 'none',
      flexDirection : 'row'
    },
    panelTitle : {
      fontSize : '24px'
    },
    panelDescription : {
      fontSize : '14px'
    },
    category : {
      float : 'right',
      width : '100%'
    },
    price : {
      float: 'right',
      marginTop : '16px',
      color : 'blue',
      width : '100%'
    },
    description : {
      width : '50%',
      float : 'left'
    },
    imgContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // position: 'absolute',
      zIndex: 1,
      maxWidth: '330px',
      maxHeight: '330px',
      overflow: 'hidden'
    },
    rightSide : {
      float: 'right',
      width : '50%',
      textAlign : 'right'
    },
    panelImage :  {
      width : '100px',
      float : 'left',
      marginRight : '10px',
      maxHeight : '105px'
    },
    leftPanel : {

    },
    rightPanel : {
      float: 'left',
      flex :1
    },
    chatItemPrimaryText: {
      color: '#000',
      flexDirection: 'right'
    },
    chatItemSecondaryText: {
      fontSize: '0.7em',
      color: '#999',
      flexDirection: 'right'
    },
    bubbleColor : {
      color: '#000000'
    },
    priceSpan : {
      color : '#2196f3'
    },
    anteriores : {
      position: 'absolute', 
      marginTop: 5, 
      background: '#FFFFFF', 
      zIndex: 999,
      border : '1px solid #f44336' 
    }
}))

const Conversation = ( props ) => {

  const me = useSelector(authSelectors.getMe(), shallowEqual);
  const client = useSelector(getContactClientInfo(props.contact.id));
  const classes = useStyles();
  const dispatch = useDispatch();
  const [outgoingMessage, setMessage] = useState('');
  const allMessages = useSelector(contactsSelector.getMessages(props.contact.id));
  const selectedContact = useSelector(contactsSelector.getContact(props.contact.id));
  const [open, setOpen] = useState(false);
  const [isOpenResponses, setOpenResponses] = useState(false);
  const [respuestas, setRespuestas] = useState([]);
  const [mensajePredef, setMensajePredef] = useState('');
  const [imagenesPredef, setImagenesPredef] = useState([]);
  const [dataPanel, setDataPanel] = useState({})
  const [newPdf, setNewPdf] = useState(false);
  const [newMsg, setNewMsg] = useState('');
  const [anterioresClicked, setAnterioresClicked] = useState(false);
  const handleProfile = props.handleProfile;
  const tieneSalientes = allMessages.some( (message) => { return message.outbound && !message.isSecret } );
  const [linkUtms, setLinkUtms] = useState(false);

  const sendMessage = (e) => {
    dispatch(contactsActions.sendMessage(e))
    setMessage('');
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect( () => {

    if (props.contact.lastMsgReference && (props.contact.source == 'chat' || props.contact.source == 'mercadolibre') ){
      Api.req.post(`https://omni.tevecompras.com/utils/messagereference/metadata` , { url :  props.contact.lastMsgReference }   )
      .then((response) => {
        setDataPanel(response.data)
     });
     // console.log(props.contact.lastMsgReference)
    }

    
  } ,[props.contact.lastMsgReference])


  const handleNewResponse = () => {
    Api.req.get(`/prospectos/campaigns/${props.contact.campaignid}/hsm`)
    .then((response) => {
       setRespuestas(response.data.data)
    });
  }

  const handleOpenResponses = () => {
    dispatch(channelActions.fetchChannelResponses(props.contact.campaignid))
    setOpenResponses(true);
  }

  const handlePdfClose = () => {
    setNewPdf(false)
  }

  const handleEmitioRespuesta = (msg) => {
    let msgReplaced = msg.replace("{1}", props.contact.name);
    setMensajePredef(msgReplaced)
    setOpenResponses(false);
  }

  const handlePdfFile = () => {
    setNewPdf(true);
  }

  const handleOnClose = () => {
    setOpen(false);
  }

  const handleAutoResponseClose = () => {
    setOpenResponses(false);
  }

  const getSearchParams = (urlParam) => {
    //var url = new URL(urlParam);
    try{
      var url = new URL(urlParam);
      let params = new URLSearchParams(url.search) 

      

      let returnParams = {
        utm_source : params.get('utm_source') ,
        utm_medium : params.get('utm_medium') ,
        utm_campaign : params.get('utm_campaign')
      };


      return returnParams;
    }
    catch(error){
      return '';
       console.error(error)
    }
    
  }
  const getPrice = () => {
    let prePrice = dataPanel['product:price:amount'] ? '$' : '';
    if(dataPanel['product:price:amount'] == undefined || dataPanel['product:price:amount'] == null ){
      return '';
    }

    return  prePrice + dataPanel['product:price:amount']
  }

  const getSubstring = (paramStr) => {
    if(paramStr.length > 15){
      return paramStr.substring(0,15) + '...';
    }else{
      return paramStr;
    }

  }

  const getTopenPanel = () => {
    let utms = dataPanel['url'] ? getSearchParams(dataPanel['url']) : null;
    if(dataPanel['twitter:image']){
      return <a href={dataPanel['url']} target="_blank" className={classes.panelContainer}> 
                <div className={classes.leftPanel}>
                  <img className={classes.panelImage} src={dataPanel['twitter:image']} alt=""/>
                </div>
                <div className={classes.rightPanel}>
                    <h3 style={{ margin: 0 }}>{dataPanel['twitter:title']} - {dataPanel['twitter:data1']} - <span className={classes.priceSpan} >{getPrice()}</span> </h3>
                    <div className={classes.description}>{dataPanel['description']}</div>
                    <div className={classes.rightSide}>
                      <div className={classes.category}>
                        { utms.utm_source ? <> <Chip label={'Utm Source : ' + utms.utm_source } size="small" color="primary"  variant="outlined"   />  <br/> </> : ''  } 
                        { utms.utm_medium ? <> <Chip label={'Utm Medium : ' + utms.utm_medium } size="small" color="primary"  variant="outlined"   />  <br/> </> : ''  }
                        { utms.utm_campaign ? <> <Chip Tooltip={utms.utm_campaign} label={'Utm Campaign : ' +  getSubstring(utms.utm_campaign) } size="small" color="primary"  variant="outlined"   />  <br/> </> : ''  } 
                      </div>
                      
                    </div>
                </div> 
            </a> ;
    }else{
      return <a href={dataPanel['url']} target="_blank" className={classes.panelContainer}> 
                <div className={classes.leftPanel}>
                  <img className={classes.panelImage} src={dataPanel['image']} alt=""/>
                </div>
                <div className={classes.rightPanel}>
                    <h3 style={{ margin: 0 }}>{dataPanel['twitter:title']}  </h3>
                    <div className={classes.description}>{dataPanel['description']}</div>
                    <div className={classes.rightSide}>
                      <div className={classes.category}>
                        { utms && utms.utm_source ? <> <Chip label={'Utm Source : ' + utms.utm_source } size="small" color="primary"  variant="outlined"   />  <br/> </> : ''  } 
                        { utms && utms.utm_medium ? <> <Chip label={'Utm Medium : ' + utms.utm_medium } size="small" color="primary"  variant="outlined"   />  <br/> </> : ''  }
                        { utms && utms.utm_campaign ? <> <Chip Tooltip={utms.utm_campaign} label={'Utm Campaign : ' +  getSubstring(utms.utm_campaign) } size="small" color="primary"  variant="outlined"   />  <br/> </> : ''  } 
                      </div>
                      
                    </div>
                </div> 
            </a> ;

    }
  }

  const getCheckIcon = (boolean) => {
    if (boolean) {
      return <CheckCircleIcon color="primary" style={{ verticalAlign: 'bottom' }} />
    } else {
      return ''
    }

  }

  const handleNewMsg = (msg) => {
    setNewMsg(msg)
  }

  const handleCreoNewMsg = () => {
    setNewMsg('')
  }

  const getIdentidad = () => {
    return (<span><Tooltip title="Identidad Verificada"><VerifiedUserIcon style={{ verticalAlign: 'bottom', color: client && client.IdentidadVerificada ? green[500] : grey[800] }} /></Tooltip> <Tooltip title="Domicilio Verificado"><HomeIcon style={{ verticalAlign: 'bottom', color: client && client.DomicilioVerificado ? green[500] : grey[800] }} /></Tooltip> {client && client.Fraudulento ? <Tooltip title="Fraudulento"><WarningIcon style={{ color: red[500] }} /></Tooltip> : ''}</span>)
  }

  const getName = (name,surname) => {
    if(surname == null){
      return name ;
    }else{
      return name + ' ' + surname;
    }
    
  }

  const getTitle = (name, surname, isStaff,campaignName, calledId,source) => {
    return (<div className={classes.pointer} onClick={handleProfile} > {getName(name, surname)}  {getCheckIcon(isStaff)} {getIdentidad()} {source == 'whatsapp' ? <div style={{ float: 'right', height: '40px', marginRight: '20px', textAlign: 'right' }} >Origen : {campaignName} <br /> {calledId}</div> : '' }    </div>  )
  }

  const handleMessageDue = (d) => {
    return !moment(d).isBefore(moment());
  }

  const verAnteriores = () => {
    dispatch(contactsActions.fetchMessages(props.contact, me.username, true));
    setAnterioresClicked(true)
  }

  const handleSendTevecomprasLink = () => {
    console.log('llega al handle')
    setLinkUtms(true)
  }

  const handleCloseModalutms = () => {
    setLinkUtms(false)
  }

  const getReferenceInfo = (image, caption, timestamp, url,media_type, media_url) => {

    const getLink = () => {
      if (media_type == 'IMAGE' || media_type == 'CAROUSEL_ALBUM'){
        return media_url;
      }else{
        return image;
      }
    }

    return <ListItem> 
              <Bubble style={{ width: 300 }}>
                <a href={url} target="_blank">
                  <div className={classes.imgContainer}>
                    <img width={380} src={getLink() } alt="" />
                  </div>
                </a>
                <br />
                <div className={classes.chatItemPrimaryText}>{caption}</div>
                
                <div className={classes.chatItemSecondaryText}>{moment(timestamp).calendar()}</div>
              </Bubble>
            </ListItem>
  }

    return (
      <>
      { props.contact ? (
        <>
          <AutoResponsesDrawer contact={props.contact}  onNewCreated={handleOpenResponses} campaignId={props.contact.campaignid} onClose={handleAutoResponseClose} isOpen={isOpenResponses} onEmitioRespuesta={handleEmitioRespuesta}  />
          <CardHeader style={{ opacity: 1, zIndex: 100, fontSize: '1rem',overflow : 'hidden' }}
            avatar={ !props.contact.profile_pic ? (<Avatar  style={{borderRadius: 50, width: 50, height: 50}}  className={[classes.IconSize, classes.pointer].join(" ")} onClick={handleProfile} />) : (<Avatar style={{borderRadius: 50, width: 50, height: 50, color: 'white' }} className={[classes.IconSize, classes.pointer].join(" ")} src={props.contact.profile_pic} onClick={handleProfile} ></Avatar>) }
            action={ <Disposition removeActiveContact={props.removeActiveContact} contact={props.contact} /> }
            title={getTitle(props.contact.name, props.contact.surname, props.contact.isStaff, props.contact.campaignName, props.contact.calledid, props.contact.source )}
            titleTypographyProps={{ fontSize: 20}}
              subheader={(<div className={classes.pointer} onClick={handleProfile}> {props.contact.callerid} </div>)}
          />
            {props.contact.lastMsgReference && props.contact.lastMsgReference !== "" && (props.contact.source == 'chat' || props.contact.source == 'mercadolibre')   ?
            getTopenPanel()
            : null }
          <ScrollToBottom mode="bottom" sticky="true" className={classes.rightContainer} followButtonClassName={classes.followB} >
            <CardContent>

                <List>
                  {props.contact.isStaff && !anterioresClicked ? <ListItem key={`${props.contact.id}`} style={{ display: 'flex', justifyContent: 'center', marginTop: 1, marginBottom: 1 }}>
                   
                    <Button onClick={verAnteriores} variant="contained" color="secondary">Mensajes Anteriores</Button>
                  </ListItem> : '' }
                  {
                    props.contact && allMessages.map((message, index, arr) => {
                      const isDayChange = moment(message.fecha).isSame(arr[index > 0 ? index - 1 : index].fecha, 'day');
                      const isAgentChange = message.agentId === arr[index > 0 ? index - 1 : index].agentId;
                      const direction = message.outbound ? 'row-reverse' : 'row'

                      return (
                        <React.Fragment key={index}>
                          
                        { (!isDayChange || index === 0) && (
                          <ListItem key={`${props.contact.id}-${index}`} style={{display: 'flex', justifyContent: 'center', marginTop: 1, marginBottom: 1}}>
                            <ListItemText primary={'--------- '+message.fechaCorta+' ---------'} style={{display: 'flex', flex: 1, justifyContent: 'center', color: '#000', marginTop: 1, marginBottom: 1}}/>
                          </ListItem>
                        )}
                          {message.messageReferenceInfo ? getReferenceInfo(message.messageReferenceInfo.thumbnail_url, message.messageReferenceInfo.caption, message.messageReferenceInfo.timestamp, message.messageReferenceInfo.permalink, message.messageReferenceInfo.media_type, message.messageReferenceInfo.media_url ) : ''}
                        <ListItem 
                          key={message.id}
                          button={false} 
                          style={{flexDirection: direction, marginTop: 1, marginBottom: 1 }}
                        >
                          <Message
                            key={message.id}
                            messageId={message.id}
                            author={message.from}
                            message={message.msg}
                            inbound={message.inbound}
                            calledid={message.calledid}
                            agentId={me.username}
                            contact={props.contact}
                            contactId={props.contact.id}
                            read={message.read}
                            delivered={message.delivered}
                            OriginMessageAttachment={message.originAttachmentURL}
                            campaignId={props.contact.campaignid}
                            time={message.fecha}
                            attachmentName={message.attachmentName }
                            messagetype={message.messagetype  }
                            messagereference={ message.messagereference }
                            sent={message.sent}
                            hasError={message.hasError}
                            errorMessage={message.errorMessage}
                            sending={message.sending}
                            outbound={message.outbound}
                            isSecret={message.isSecret}
                            readBy={message.ReadByAgent}
                            isAgentChange={isAgentChange}
                            reacted={message.reacted}
                            messageReferenceInfo={message.messageReferenceInfo}
                            messageObject={message}
                          />
                        </ListItem>
                        </React.Fragment>
                      )
                    })
                  }
                </List> 
            {/* { !sticky && <button onClick={ scrollToBottom }>Click me to scroll to bottom</button> } */}
            </CardContent>
          </ScrollToBottom>
            {!props.todas && !(props.contact.source == 'mercadolibre' && props.contact.status == 10) ? (
            <CardActions style={{ opacity: 1, zIndex: 100, height: '120px' }}>
              <ActionsButton dataPanel={getSearchParams(dataPanel['url'])} onSendTevecomprasLink={handleSendTevecomprasLink} onPdfFile={handlePdfFile} onNewMsg={handleNewMsg}  campaignid={props.contact.campaignid} respuestas={respuestas} onOpenResponses={ handleOpenResponses} classes={classes} contact={props.contact} messageDue={handleMessageDue(props.contact.messageDue)} />
              {  handleMessageDue(selectedContact.messageDue) && !props.todas && selectedContact.transfered == undefined   ? (
                <>
                    <SendBox linkUtms={linkUtms} onCloseModalUtms={handleCloseModalutms} dataPanel={getSearchParams(dataPanel['url'])} onPdfClose={handlePdfClose} newPdf={newPdf} newMsg={newMsg} onNewCreated={handleCreoNewMsg} tieneSalientes={tieneSalientes} mensajePredef={mensajePredef} classes={classes} contact={props.contact} />
                </>
              ):(
                <>
                <Paper style={{backgroundColor: 'red', display: 'flex', flex: 1, flexDirection: 'column', justifyContent:'center', alignItems: 'center', height: 90}} >
                    <Typography variant="h5" component="h3">
                      {selectedContact.transfered ? 'El contacto ha sido reasignado' : <> 'Conversación iniciada hace más de 24hs' < ChatOffline messageDue={true} contact={props.contact} /> </>  }
                      
                    </Typography>
                    <Typography component="p">
                    { selectedContact.transfered ? '' : 'Deberá enviar un mensaje predefinido para contactar al Cliente'  }
                      
                    </Typography>
                </Paper>
                </>
              )
              }
            </CardActions>
          ) : null }
          </>
      ) : (
        <ChatSkeleton classes={classes} />
      )}
      </>
    )
}

export default Conversation;