import produce from 'immer';

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from './constants';

export const initialState = {
  me: null,
  isSuper: false,
  isAdmin: false,
  isMarketingUser : false,
  loggingIn: false,
  loginError: null,
  loginAuto: false,
  theme: 'dark'
};

const reducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case LOGIN:
      draft.loggingIn = true;
      draft.loginError = null;
      draft.loginAuto = !!action.auto;
      break;

    case LOGIN_SUCCESS:
      draft.me = action.data;
      draft.loggingIn = false;
      draft.loginError = null;
      draft.loginAuto = false;
      draft.isAdmin = action.data.isAdmin;
      draft.isSuper = action.data.isSuper;
      draft.isMarketingUser = action.data.isMarketingUser;
      draft.displayname = action.data.displayname;
      break;

    case LOGIN_FAIL:
      draft.loggingIn = false;
      draft.loginError = action.error;
      draft.loginAuto = false;
      break;

    case LOGOUT:
      draft.loggingIn = false;
      draft.loginAuto = false;
      draft.me = null;
      break;

    default: 
      // draft = state;
      break;
  }
});

export default reducer;
