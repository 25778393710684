import React,{ useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector , shallowEqual, useDispatch } from 'react-redux';
import * as authSelectors from '../../../state/auth/selectors';
import Api from '../../../state/Api';
import * as appActions from '../../../state/app/actions';
import Button from '@material-ui/core/Button';
import { Box, Avatar,Grid, Divider, FormControl } from "@material-ui/core";
import BlockUi from 'react-block-ui';
import * as contactActions from '../../../state/contacts/actions';

const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center'
      },
    },
    content: {
        width: '100%'
        // margin: theme.spacing(1),
    },
}));

const Datos = ( props ) => {
  
    useEffect(() => {
        setNombre(props.contact.name ? props.contact.name : '')
        setApellido(props.contact.surname ? props.contact.surname : '')
        setTipoDocu(props.contact.Tipo_Docu ? props.contact.Tipo_Docu : '' )
        setNroDocumento(props.contact.Nro_Docu ? props.contact.Nro_Docu : '')
        setUtmCampaign(props.contact.utm_campaign ? props.contact.utm_campaign : '')
        setUtmMedium(props.contact.utm_medium ? props.contact.utm_medium : '')
        setUtmSource(props.contact.utm_source ? props.contact.utm_source : '')
        setEmail(props.contact.E_Mail ? props.contact.E_Mail : '')
        setCallerid(props.contact.callerid)
        setCampaignId(props.contact.campaignid)
        setContactId(props.contact.contactid)
    }, [props]);

    const dispatch = useDispatch();
    const classes = useStyles();
    const me = useSelector(authSelectors.getMe(), shallowEqual);
    const [id, setId] = React.useState(props.contact.id);
    const [nombre, setNombre] = React.useState(props.contact.name ? props.contact.name : '');
    const [apellido, setApellido] = React.useState(props.contact.surname ? props.contact.surname : '');
    const [tipoDocu, setTipoDocu] = React.useState(props.contact.Tipo_Docu ? props.contact.Tipo_Docu : '' );
    const [nroDocumento, setNroDocumento] = React.useState(props.contact.Nro_Docu ? props.contact.Nro_Docu : '');
    const [utm_campaign, setUtmCampaign] =  React.useState(props.contact.utm_campaign ? props.contact.utm_campaign : '');
    const [utm_medium, setUtmMedium] = React.useState(props.contact.utm_medium ? props.contact.utm_medium : '');
    const [utm_source , setUtmSource] =  React.useState(props.contact.utm_source ? props.contact.utm_source : '');
    const [email, setEmail] = React.useState(props.contact.E_Mail ? props.contact.E_Mail : '');
    const [callerid, setCallerid] = React.useState(props.contact.callerid);
    const [campaignid, setCampaignId] = React.useState(props.contact.campaignid);
    const [contactid, setContactId] = React.useState(props.contact.contactid);
    const [guardando, setGuardando] = React.useState(false);

    const banearIp = () => {
        setGuardando(true)
        let datos = {
            "campaignid": campaignid
        };
  
        Api.req.put(`/prospectos/agent/${me.username}/prospecto/${contactid}/ban/`,datos)
        .then((response) => {
            setGuardando(false)
            dispatch(appActions.addToast('Contacto Baneado'))
        })
        .catch(error => {
            setGuardando(false)
            dispatch(appActions.addToast('Ha habido un error','error'))
        })

    }

    const guardarInfo = () => {
        setGuardando(true)
        let datos = {
            "campaignid": String(campaignid),
            "name":nombre,
            "surname": apellido,
            "Tipo_Docu":  tipoDocu,
            "Nro_Docu": nroDocumento,
            "E_Mail": email,
            "callerid": callerid,
            "utm_campaign" : utm_campaign,
            "utm_medium" : utm_medium ,
            "utm_source" : utm_source
        };

        
        Api.req.put(`/prospectos/agent/${me.username}/prospecto/${contactid}/profile`,datos)
        .then((response) => {
            setGuardando(false)
            dispatch(appActions.addToast('Contacto Modificado'))
            datos.contactid = contactid;
            dispatch(contactActions.updateContactDatos(datos))
            props.handleGuardoDatos(datos)
        })
        .catch(error => {
            setGuardando(false)
            dispatch(appActions.addToast('Ha habido un error','error'))
            console.log('Ha habido un error')
        })
    } 

    const  handleTipoDocChange = (event) => {
        setTipoDocu(event.target.value);
    }
    const currencies = [
        {
            value : '',
            label : 'Seleccione'
        },
        {
          value: '1',
          label: 'DNI'
        },
        {
          value: '2',
          label: 'CI'
        },
        {
          value: '3',
          label: 'LE'
        },
        {
            value: '4',
            label: 'LC'
        },
        {
            value: '5',
            label: 'PAS'
        },
        {
            value: '6',
            label: 'OTR'  
        },
        {
            value : '7',
            label : 'CUIT'
        }
      ];
    return (
        <div className={classes.root}>
            <form noValidate autoComplete="off">
                <input type="hidden" value="prayer" />
                {/* <Avatar style={{borderRadius: 50, width: 150, height: 150, color: 'white', padding: '20', margin: '0 auto' }} src={props.contact.profile_pic} ></Avatar> */}
                {/* <br style={{ height: 20}}></br> */}
                <div>
                    <TextField   
                        variant="outlined" 
                        label="Nombre"
                        width="200px" 
                        size="small"
                        onChange={(event)=> setNombre(event.target.value)} 
                        value={nombre}
                    />
                    <TextField   
                        variant="outlined" 
                        label="Apellido"
                        width="200px" 
                        size="small"
                        onChange={(event)=> setApellido(event.target.value)} 
                        value={apellido}
                    />
                </div>
                <Grid container>
                    <Grid item xs={6}>
                        <TextField
                            id="standard-select-currency"
                            select
                            label="Tipo Documento"
                            variant="outlined"
                            value={tipoDocu}
                            onChange={handleTipoDocChange}
                            size="small"
                            >
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="filled-error-helper-text"
                            label="Documento"
                            variant="outlined"
                            size="small"
                            onChange={(event)=> setNroDocumento(event.target.value)} 
                            value={nroDocumento}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4}>
                        <TextField
                            id="filled-error-helper-text"
                            label="utm_campaign"
                            variant="outlined"
                            size="small"
                            onChange={(event)=> setUtmCampaign(event.target.value)} 
                            value={utm_campaign}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            id="filled-error-helper-text"
                            label="utm_medium"
                            variant="outlined"
                            size="small"
                            onChange={(event)=> setUtmMedium(event.target.value)} 
                            value={utm_medium}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            id="filled-error-helper-text"
                            label="utm_source"
                            variant="outlined"
                            size="small"
                            onChange={(event)=> setUtmSource(event.target.value)} 
                            value={utm_source}
                        />
                    </Grid>
                </Grid>
                <div>
                    <TextField
                        id="outlined-error"
                        label="Caller ID / Profile ID"
                        variant="outlined"
                        size="small"
                        onChange={(event)=> setCallerid(event.target.value)} 
                        value={callerid}
                    />
                </div>
                <FormControl fullWidth className={classes.margin} variant="filled">
                    <TextField
                        id="outlined-error-helper-text"
                        label="Email"
                        variant="outlined"
                        size="small"
                        onChange={(event)=> setEmail(event.target.value)} 
                        value={email}
                    />
                </FormControl>
                <Box>
                    <Button disabled={guardando} onClick={() => guardarInfo()} style={{ marginLeft: '8px' }} variant="contained" color="primary">
                        Guardar Info
                    </Button>
                    <Button disabled={guardando} onClick={() => banearIp()} style={{ float: 'right' }} variant="contained" color="secondary">
                        Banear IP
                    </Button>
                </Box>
            </form>
        </div>
    )
};

export default React.memo(Datos);